import { Button, Cascader, Col, DatePicker, Form, Input, Row, Select, Slider } from 'antd'
import { useTranslation } from 'react-i18next';
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { EventCurrency, EventSurface } from 'App/common/enums';
import { allCountries } from 'country-region-data';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { EventSearchFormValues } from './EventSearchFormParams';
import moment from 'moment';
import PageLabel from 'App/common/components/PageLabel/PageLabel';
import countries from 'i18n-iso-countries';
import i18n from 'i18n';
import { Desktop } from 'App/common/components/Responsive/Desktop';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';

import './EventSearchForm.less';
import { formRules } from 'App/common/formRules/formRules';

interface EventSearchFormParams {
    loading?: boolean;
    initialValues?: EventSearchFormValues;
    availableCountries?: string[];
    onFinish: (params: EventSearchFormValues) => void;
}

export default function EventSearchForm({ loading, initialValues, availableCountries, onFinish } : EventSearchFormParams) {
	const { t } = useTranslation(['form', 'common', 'models', 'page']);
    const { title, city, distance } = formRules.searchEvent;

    const [form] = Form.useForm();
    const categoryCascader = useRef(null);
    
    const initialCountry = () => {
        const init = [];
        if(initialValues?.country) {
            init.push(initialValues.country)
            if(initialValues.state)
                init.push(initialValues.state);
        }
        return init.length ? init : undefined;
    }
    const [eCountry, setECountry] = useState<string[]>(initialCountry());
    const [eCity, setECity] = useState<string>(null);

    const [showMore, setShowMore] = useState<boolean>(false);
    
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [currency, setCurrency] = useState(EventCurrency[EventCurrency.PLN] as any);

    useEffect(() => {
        document.addEventListener('keypress', (event) => {
            if(event.key === 'Enter')
                form.validateFields().then(vals => parseParams(vals));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        const ds = initialValues?.dateStart ? moment(initialValues.dateStart, "YYYY-MM-DD:HH:mm") : null;
        const de = initialValues?.dateEnd ? moment(initialValues.dateEnd, "YYYY-MM-DD:HH:mm") : null;

        form.setFieldsValue({
            ...initialValues,
            dateStart: ds,
            dateEnd: de,
            priceEntry: initialValues?.priceEntry ?? [0, 2100],
            priceTicket: initialValues?.priceTicket ?? [0, 600],
            categories: initialValues?.categories?.map(cat => EventCategoryGrouping.categoryToPath(t, cat)),
            country: initialCountry()
        });

        setDateStart(ds);
        setDateEnd(de);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, form, t])

    useEffect(() => {
        setECity("");

        form.setFieldsValue({
            'country': eCountry,
            'state': null,
            'city': null,
        });
    }, [eCountry, form]);

    const sliderEntryMax = 2100;
    const sliderTicketMax = 600;

    const sliderEntryFormat = (value: number) => {
        if(value <= 0)
            return t('page:EventPages.SubmitEventForm.Free');
        if(value > 2000)
            return t('page:EventPages.SubmitEventForm.Max');

        return `${value} ${currency}`;
    }
    const sliderTicketFormat = (value: number) => {
        if(value <= 0)
            return t('page:EventPages.SubmitEventForm.Free');
        if(value > 500)
            return t('page:EventPages.SubmitEventForm.Max');

        return `${value} ${currency}`;
    }
    const sliderEntryMarks = {
        0: {
            style: { color: 'white', fontSize: 'smaller' },
            label: t('page:EventPages.SubmitEventForm.Free')
        },
        1000: {
            style: { color: 'white', fontSize: 'smaller' },
            label: `1000 ${currency}`
        },
        2100: {
            style: { color: 'white', fontSize: 'smaller' },
            label: t('page:EventPages.SubmitEventForm.Max')
        }
    };
    const sliderTicketMarks = {
        0: {
            style: { color: 'white', fontSize: 'smaller' },
            label: t('page:EventPages.SubmitEventForm.Free')
        },
        300: {
            style: { color: 'white', fontSize: 'smaller' },
            label: `300 ${currency}`
        },
        600: {
            style: { color: 'white', fontSize: 'smaller' },
            label: t('page:EventPages.SubmitEventForm.Max')
        }
    };
    const sliderStyle: CSSProperties[] = [{
        backgroundColor: 'white'
    }];

    const parseParams = async (params: EventSearchFormValues) => {
        form.validateFields().then(() => {
            if(params.priceEntry && params.priceEntry[0] === 0 && params.priceEntry[1] === sliderEntryMax)
                params.priceEntry = null;

            if(params.priceTicket && params.priceTicket[0] === 0 && params.priceTicket[1] === sliderTicketMax)
                params.priceTicket = null;

            if(params.priceTicket || params.priceEntry)
                params.priceCurrency = currency;
            else
                params.priceCurrency = null;

            if(params.categories?.length === 0)
                params.categories = null;
            else if(params.categories)
                params.categories = params.categories.map((c: any) => c[c.length - 1]);

            if(params.surfaces?.length === 0)
                params.surfaces = null;

            if(params.dateStart)
                params.dateStart = params.dateStart.startOf('day');

            if(params.dateEnd)
                params.dateEnd = params.dateEnd.endOf('day');

            if(eCountry?.length > 0) {
                params.country = eCountry[0] ?? null;

                if(eCountry.length >= 1)
                    params.state = eCountry[1] ?? null;
            }

            console.log("ONFINISH");
            onFinish(params);
        });
    }

    const countryList = countries.getNames(i18n.language, { select: 'official' });
    const countryOptions = availableCountries?.map(c => (
        {
            value: c,
            label: countryList[c],
            children: allCountries.find(ac => ac[1] === c)[2].map(r => ({ value: r[0], label: r[0] }))
        }
    ));

    const unallowedStartDates = (current) => {
        const today = moment().startOf('day');
        const disable = (dateEnd ? current && current > dateEnd : false);

        return disable || (current < today);
    }

    const unallowedEndDates = (current) => {
        const today = moment.utc().startOf('day');
        const disable = (dateStart ? current && current < dateStart : false);
        
        return disable || (current < today);
    }
    
    return (
        <div className='search-form'>
            <PageLabel message={t('page:HomePages.SearchEvents')} />
            <Form 
                layout='vertical' 
                labelAlign='right' 
                initialValues={initialValues} 
                onFinish={parseParams} 
                form={form}
                className='search-form__form'
            >
                <Row gutter={[60, 0]}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('page:HomePages.Search.Title') }}
                            label={t('page:HomePages.Search.Title')}
                            name='query'
                            rules={title()}
                        >
                            <Input placeholder={t('models:Event.Placeholders.Title')} allowClear />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.Category') }}
                            label={t('models:Event.Labels.Category')}
                            name='categories'
                        >
                            <Cascader
                                placeholder={t('models:Event.Placeholders.Category')}
                                options={EventCategoryGrouping.toOptions(t)}
                                multiple
                                maxTagCount={'responsive'}
                                showCheckedStrategy={'SHOW_CHILD'}
                                expandTrigger={'hover'}
                                ref={categoryCascader}
                                onChange={() => {
                                    //console.log(categoryCascader)
                                    categoryCascader.current.blur();
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.Surface') }}
                            label={t('models:Event.Labels.Surface')}
                            name='surfaces'
                        >
                            <Select 
                                placeholder={t('models:Event.Placeholders.Surface')}
                                mode='multiple'
                                allowClear
                            >
                                {(Object.keys(EventSurface) as Array<keyof typeof EventSurface>).map((key) => {
                                    if(isNaN(Number(key)))
                                        return <Select.Option key={key} value={key}>{t(`models:Event.Surface.${key}`)}</Select.Option>
                                    else
                                        return null;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    <Desktop>
                        <Col md={12} xs={24}>
                            <Form.Item
                                messageVariables={{ arg: t('models:Event.Labels.Country') }}
                                label={`${t('models:Event.Labels.Country')} / ${t('models:Event.Labels.State')}`}
                                name='country'
                            >
                                <Cascader
                                    placeholder={`${t('models:Event.Placeholders.Country')} / ${t('models:Event.Labels.State').toLowerCase()}`}
                                    options={countryOptions?.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                                    changeOnSelect
                                    maxTagCount={'responsive'}
                                    showCheckedStrategy={'SHOW_CHILD'}
                                    onChange={(val) => setECountry(val)}
                                />
                            </Form.Item>
                        </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.City') }}
                            label={t('models:Event.Labels.City')}
                            name='city'
                            rules={city()}
                        >
                            <Input 
                                placeholder={t('models:Event.Placeholders.City')} 
                                onChange={(val) => setECity(val.target.value)} 
                                value={eCity} 
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('page:HomePages.Search.Distance') }}
                            label={t('page:HomePages.Search.Distance')}
                            name='distance'
                            rules={distance()}
                        >
                            <Input
                                placeholder={t('models:Event.Placeholders.Distance')}
                                min={0}
                                type="number"
                                addonAfter={"km"}
                                style={{ borderRadius: '7px 0px 0px 7px !important'}}
                            />
                        </Form.Item>
                    </Col>
                    </Desktop>

                    <Mobile>
                    {showMore && <>
                    <Col md={12} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.Country') }}
                            label={`${t('models:Event.Labels.Country')} / ${t('models:Event.Labels.State')}`}
                            name='country'
                        >
                            <Cascader
                                placeholder={`${t('models:Event.Placeholders.Country')} / ${t('models:Event.Labels.State').toLowerCase()}`}
                                options={countryOptions?.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                                changeOnSelect
                                maxTagCount={'responsive'}
                                showCheckedStrategy={'SHOW_CHILD'}
                                onChange={(val) => setECountry(val)}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.City') }}
                            label={t('models:Event.Labels.City')}
                            name='city'
                            rules={city()}
                        >
                            <Input 
                                placeholder={t('models:Event.Placeholders.City')} 
                                onChange={(val) => setECity(val.target.value)} 
                                value={eCity} 
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('page:HomePages.Search.Distance') }}
                            label={t('page:HomePages.Search.Distance')}
                            name='distance'
                            rules={distance()}
                        >
                            <Input
                                placeholder={t('models:Event.Placeholders.Distance')}
                                min={0}
                                type="number"
                                addonAfter={"km"}
                                style={{ borderRadius: '7px 0px 0px 7px !important'}}
                            />
                        </Form.Item>
                    </Col>
                    </>}
                    </Mobile>

                    {showMore && <>
                    <Col md={6} xs={12}>
                        <Form.Item
                            messageVariables={{ arg: `${t('models:Event.Labels.DateStart')}` }}
                            label={t('models:Event.Labels.DateStart')}
                            name='dateStart'
                        >
                            <DatePicker
                                showTime={false}
                                format="YYYY-MM-DD"
                                value={dateStart}
                                onChange={setDateStart}
                                disabledDate={unallowedStartDates}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Item
                            messageVariables={{ arg: `${t('models:Event.Labels.DateEnd')}` }}
                            label={t('models:Event.Labels.DateEnd')}
                            name='dateEnd'
                        >
                            <DatePicker
                                showTime={false}
                                format="YYYY-MM-DD"
                                value={dateEnd}
                                onChange={setDateEnd}
                                disabledDate={unallowedEndDates}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.SafetyCageRequired') }}
                            label={t('models:Event.Labels.SafetyCageRequired')}
                            name='safetyCageRequired'
                        >
                            <Select placeholder={t('models:Event.Placeholders.SafetyCageRequired')} allowClear>
                                <Select.Option value={'true'}>{t('common:Actions.Yes')}</Select.Option>
                                <Select.Option value={'false'}>{t('common:Actions.No')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col md={6} />

                    <Col md={6} xs={24} className='slider-col'>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.PriceEntry') }}
                            label={t('models:Event.Labels.PriceEntry')}
                            name='priceEntry'
                        >
                            <Slider 
                                range 
                                min={0}
                                step={100}
                                max={sliderEntryMax}
                                //defaultValue={[0, sliderMax]}
                                marks={sliderEntryMarks}
                                tipFormatter={sliderEntryFormat}
                                trackStyle={sliderStyle}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24} className='slider-col'>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.PriceTicket') }}
                            label={t('models:Event.Labels.PriceTicket')}
                            name='priceTicket'
                        >
                            <Slider 
                                range 
                                min={0}
                                step={100}
                                max={sliderTicketMax}
                                //defaultValue={[0, sliderMax]}
                                marks={sliderTicketMarks}
                                tipFormatter={sliderTicketFormat}
                                trackStyle={sliderStyle}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                        <Form.Item
                            messageVariables={{ arg: t('models:Event.Labels.PriceCurrency') }}
                            label={`${t('models:Event.Labels.PriceCurrency')}:`}
                            name='currency'
                        >
                            <Select defaultValue={currency} onChange={setCurrency}>
                                {(Object.keys(EventCurrency) as Array<keyof typeof EventCurrency>).map((key) => {
                                    if(isNaN(Number(key)))
                                        return <Select.Option key={key} value={key}>{key}</Select.Option>
                                    else
                                        return null;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    </>}
                </Row>

                <Row>
                    {showMore
                    ? (
                        <p className='show-button' onClick={() => setShowMore(false)}>
                            <u>
                                {t('common:Actions.ShowLess')} <ArrowUpOutlined/>
                            </u>
                        </p>
                    )
                    : (
                        <p className='show-button' onClick={() => setShowMore(true)}>
                            <u>
                                {t('common:Actions.ShowMore')} <ArrowDownOutlined/>
                            </u>
                        </p>
                    )}
                </Row>

                <Row>
                    <Form.Item>
                        <Button 
                            block 
                            size='large'
                            loading={loading} 
                            type='primary' 
                            htmlType='submit'
                            className='search-button'
                        >
                            {t('common:Actions.Search')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}
